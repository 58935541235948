<template>
  <b-modal
      v-if="elementsToDetail"
      :title="capitalize(title)"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
  >
    <purchases-types-indicators
        :purchase-types="elementsToDetail"
    />

  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import PurchasesTypesIndicators from '../../indicatorDetails/ExpensesTypesIndicators'
import moment from 'moment'

export default {
  components: {
    PurchasesTypesIndicators
  },
  props: {
    date: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    elementsToDetail: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(props.isOpen)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const title = computed(() => {
      return moment(props.date + '-01').format('MMMM YYYY')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,

      // Computed
      title,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.nav-tabs {
  margin-bottom: 0rem;
}
</style>