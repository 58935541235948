<template>
  <div>
    <transition name="fade">
      <b-card no-body
              v-if="Object.entries(expensesIndicator).length !== 0">
        <b-card-header class="align-items-baseline">
          <div>
            <b-card-title class="mb-25">
              <span class="my-auto"
                    v-if="isInvoiced">{{ $t('Invoiced') }}
              </span>
              <span class="my-auto"
                    v-else>{{ $t('Ordered') }}
              </span>

              <div
                  v-if="employees.length > 0"
                  class="d-inline-flex ml-2"
              >
                <b-alert
                    show
                    variant="primary"
                    class="d-inline mb-0"
                >
                  <div class="alert-body d-flex py-25">
                    <div class="d-flex">
                      <icon class="d-flex my-auto"
                            icon="info-circle"/>
                    </div>
                    <small class="ml-25 my-auto">
                      {{ $t('It does not take into account charges') }}
                    </small>
                  </div>
                </b-alert>
              </div>

            </b-card-title>
            <b-card-text class="mb-0">
              <span v-if="isInvoiced">{{ $t('TotalInvoiced:') }} {{ currency(expensesIndicator.expenses) }}</span>
              <span v-else>{{ $t('TotalOrdered:') }} {{ currency(totalOrdered) }}</span>

            </b-card-text>
          </div>
          <div>
            <switch-input
                :model.sync="isInvoiced"
                name="Ordered/Invoiced"
            >
              <template #iconLeft>
                <icon icon="euro-sign"/>
              </template>
              <template #iconRight>
                <icon icon="shopping-cart"/>
              </template>

            </switch-input>
          </div>
        </b-card-header>

        <b-card-body class="">

          <line-chart
              :labels="xAxis"
              :series="yAxix"
              @click="chartClick"
          />

          <b-row
              v-for="(type, index) in expensesByPercentDesc"
              class="mt-1"
          >
            <b-col cols="4">
              {{ type.type }}
            </b-col>
            <b-col cols="5">
              <b-progress
                  :key="index"
                  :value="(type.percent * 100)"
                  :id="'popover-expense-type-'+index"
              />
              <b-popover
                  :target="'popover-expense-type-'+index"
                  placement="bottom"
                  triggers="hover"
              >
                <template #title>
                  {{ type.type }}
                </template>
                <div>
                  <!--                        <div class="d-flex justify-content-between">-->
                  <!--                          <span>{{ $t('Quantity:') }}&nbsp;</span>-->
                  <!--                          <span class="text-right">-->
                  <!--                            <b>{{ product.quantity }}</b>-->
                  <!--                          </span>-->
                  <!--                        </div>-->
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('PreTaxAmount:') }}&nbsp;</span>
                    <span class="text-right">
                      <b>{{ currency(type.preTaxAmount) }}</b>
                    </span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('Average:') }}&nbsp;</span>
                    <span class="text-right">
                      <b>{{ currency(getAverage(type.averages)) }}</b>
                    </span>
                  </div>
                </div>
              </b-popover>
            </b-col>
            <b-col cols="3"
                   class="text-right">
              <span v-if="!isNaN(type.percent)">{{ percent((type.percent * 100), 0) }}</span>
              <span v-else>{{ percent(0, 0) }}</span>
            </b-col>

          </b-row>

        </b-card-body>
      </b-card>
    </transition>

    <!--    Detail modal-->
    <prompt-detail-purchase-types
        :date="modalTitle"
        :isOpen.sync="isModalDetailOpen"
        :elementsToDetail="elementsToDetail"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useIndicators } from '../useIndicators'
import { capitalize, currency, percent } from '../../../utils/filter'

import SwitchInput from '../../input/Switch'
import useAPI from '../../../utils/useAPI'
import LineChart from '../../chart/Line'
import moment from 'moment'
import i18n from '@/libs/i18n'
import PromptDetailPurchaseTypes from '../components/_promptDetailPurchaseTypes'

export default {
  components: {
    SwitchInput,
    LineChart,
    PromptDetailPurchaseTypes,
  },
  props: {
    start: {
      type: String,
      default: null
    },
    end: {
      type: String,
      default: null
    },
    employees: {
      type: Array,
      default: () => []
    }
    // isSales: {
    //   type: Boolean,
    //   default: false
    // }
  },
  setup (props) {
    const { fetchExpensesIndicator, expensesIndicator } = useAPI()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startLocal = ref(moment(props.start))
    const endLocal = ref(moment(props.end))
    const employeesLocal = ref(props.employees)

    const isInvoiced = ref(true)
    const totalOrdered = ref(0)
    const totalInvoiced = ref(0)

    const isModalDetailOpen = ref(false)
    const modalTitle = ref(null)
    const elementsToDetail = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const expensesByMonths = computed(() => {
      return expensesIndicator.value.expensesByMonths
    })

    const xAxis = computed(() => {
      let months = []
      Object.keys(expensesByMonths.value).forEach(em => months.push(em))

      return months
    })

    const yAxix = computed(() => {
      let output = []

      if (props.isSales) {
        // if (isInvoiced.value) {
        //   salesByMonths.value.forEach(item => output.push(item.turnover.products.invoicedProductsTotal))
        // } else {
        //   salesByMonths.value.forEach(item => output.push(item.turnover.products.offeredProductsTotal))
        // }
      } else {
        if (isInvoiced.value) {
          Object.keys(expensesByMonths.value).forEach(item => {
            output.push(expensesByMonths.value[item].expenses)
            // let amount = expensesByMonths.value[item].detail.charges
            // amount += expensesByMonths.value[item].detail.mileageAllowances
            // amount += expensesByMonths.value[item].detail.salaries
            // amount += expensesByMonths.value[item].detail.incomingInvoices
            // // amount += expensesByMonths.value[item].products.invoicedTotal
            //
            // output.push(amount)
          })
        } else {
          Object.keys(expensesByMonths.value).forEach(item => {
            let amount = expensesByMonths.value[item].detail.charges
            amount += expensesByMonths.value[item].detail.mileageAllowances
            amount += expensesByMonths.value[item].detail.salaries
            // amount += expensesByMonths.value[item].products.orderedTotal

            output.push(amount)
          })
        }
      }

      return [
        {
          name: isInvoiced.value ? i18n.t('Invoiced') : i18n.t('Ordered'),
          data: output
        }]
    })

    const expensesByPercentDesc = computed(() => {
      let expensesByPercentDesc = [
        {
          type: i18n.t('Salaries:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('Charges:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('ClassicExpenses:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('TransportExpenses:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('CateringExpenses:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('AccomodationExpenses:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('ProductionExpenses:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        },
        {
          type: i18n.t('MileageAllowances:'),
          preTaxAmount: 0,
          percent: 0,
          averages: [],
        }
      ]

      Object.values(expensesByMonths.value).forEach(e => {

        // Salaries
        expensesByPercentDesc[0].preTaxAmount += parseFloat(e.detail.salaries)
        expensesByPercentDesc[0].averages.push(parseFloat(e.detail.salariesAverage))
        expensesByPercentDesc[0].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[0].preTaxAmount / expensesIndicator.value.expenses)

        // Charges
        expensesByPercentDesc[1].preTaxAmount += parseFloat(e.detail.charges)
        expensesByPercentDesc[1].averages.push(parseFloat(e.detail.chargesAverage))
        expensesByPercentDesc[1].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[1].preTaxAmount / expensesIndicator.value.expenses)

        // Mileage allowance
        expensesByPercentDesc[7].preTaxAmount += parseFloat(e.detail.mileageAllowances)
        expensesByPercentDesc[7].averages.push(parseFloat(e.detail.mileageAllowancesAverage))
        expensesByPercentDesc[7].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[7].preTaxAmount / expensesIndicator.value.expenses)

        // IncomingInvoices
        e.detail.incomingInvoiceEntities.forEach(incomingInvoiceEntity => {
          if (incomingInvoiceEntity.type == 'default') {
            expensesByPercentDesc[2].preTaxAmount += parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount))
            expensesByPercentDesc[2].averages.push(parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount)))
            expensesByPercentDesc[2].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[2].preTaxAmount / expensesIndicator.value.expenses)
          } else if (incomingInvoiceEntity.type == 'toll') {
            expensesByPercentDesc[3].preTaxAmount += parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount))
            expensesByPercentDesc[3].averages.push(parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount)))
            expensesByPercentDesc[3].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[3].preTaxAmount / expensesIndicator.value.expenses)
          } else if (incomingInvoiceEntity.type == 'catering') {
            expensesByPercentDesc[4].preTaxAmount += parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount))
            expensesByPercentDesc[4].averages.push(parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount)))
            expensesByPercentDesc[4].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[4].preTaxAmount / expensesIndicator.value.expenses)
          } else if (incomingInvoiceEntity.type == 'accommodation') {
            expensesByPercentDesc[5].preTaxAmount += parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount))
            expensesByPercentDesc[5].averages.push(parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount)))
            expensesByPercentDesc[5].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[5].preTaxAmount / expensesIndicator.value.expenses)
          } else if (incomingInvoiceEntity.type == 'production') {
            expensesByPercentDesc[6].preTaxAmount += parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount))
            expensesByPercentDesc[6].averages.push(parseFloat(parseFloat(incomingInvoiceEntity.preTaxAmount)))
            expensesByPercentDesc[6].percent = expensesIndicator.value.expenses == 0 ? 0 : (expensesByPercentDesc[6].preTaxAmount / expensesIndicator.value.expenses)
          }
        })

      })

      expensesByPercentDesc.sort((a, b) => b.percent - a.percent)

      return expensesByPercentDesc
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(startLocal, () => {
      // console.log(startLocal.value)
      if (startLocal.value != null && endLocal.value != null) {
        // fetchExpensesIndicator({
        //   start: moment(props.start).format('YYYY-MM-DD'),
        //   end: moment(props.end).format('YYYY-MM-DD')
        // })
        reloadIndicators()
      }
    })

    watch(() => props.employees, val => {
      employeesLocal.value = val
      reloadIndicators()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const chartClick = (index) => {
      populateElementsToDetail(index)

      elementsToDetail.value.sort((a, b) => b.preTaxAmount - a.preTaxAmount)

      modalTitle.value = Object.keys(expensesByMonths.value)[index]
      isModalDetailOpen.value = true
    }

    const populateElementsToDetail = (index) => {
      elementsToDetail.value = [
        {
          type: i18n.t('Salaries:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('Charges:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('ClassicExpenses:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('TransportExpenses:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('CateringExpenses:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('AccomodationExpenses:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('ProductionExpenses:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('MileageAllowances:'),
          preTaxAmount: 0,
          averages: [],
        },
        {
          type: i18n.t('Orders:'),
          preTaxAmount: 0,
          averages: [],
        }
      ]

      // if (!isInvoiced.value) {
      // elementsToDetail.value.push([
      //   {
      //     type: i18n.t('Orders:'),
      //     preTaxAmount: 0,
      //     averages: [],
      //   },
      // ])

      // let currentType = purchaseByMonths.value[index].purchases.types.ordered
      //
      // // Orders
      // elementsToDetail.value[0].preTaxAmount += currentType.ordersTotal
      // elementsToDetail.value[0].average += currentType.orders.reduce((pSum, a) => pSum + parseFloat(a.preTaxAmount), 0) / currentType.orders.length
      // }

      // Object.keys(expensesByMonths.value).forEach(item => {
      let currentExpense = Object.values(expensesByMonths.value)[index]
      // console.log(currentExpense)
      // Salaries
      elementsToDetail.value[0].preTaxAmount += currentExpense.detail.salaries
      elementsToDetail.value[0].averages.push(currentExpense.detail.salariesAverage)
      // Charges
      elementsToDetail.value[1].preTaxAmount += currentExpense.detail.charges
      elementsToDetail.value[1].averages.push(currentExpense.detail.chargesAverage)
      // MileageAllowance
      elementsToDetail.value[7].preTaxAmount += currentExpense.detail.mileageAllowances
      elementsToDetail.value[7].averages.push(currentExpense.detail.mileageAllowancesAverage)

      // IncomingInvoices
      currentExpense.detail.incomingInvoiceEntities.forEach(incomingInvoiceEntity => {
        if (incomingInvoiceEntity.type == 'default') {
          elementsToDetail.value[2].preTaxAmount += parseFloat(incomingInvoiceEntity.preTaxAmount)
          elementsToDetail.value[2].averages.push(parseFloat(incomingInvoiceEntity.preTaxAmount))
        } else if (incomingInvoiceEntity.type == 'toll') {
          elementsToDetail.value[3].preTaxAmount += parseFloat(incomingInvoiceEntity.preTaxAmount)
          elementsToDetail.value[3].averages.push(parseFloat(incomingInvoiceEntity.preTaxAmount))
        } else if (incomingInvoiceEntity.type == 'catering') {
          elementsToDetail.value[4].preTaxAmount += parseFloat(incomingInvoiceEntity.preTaxAmount)
          elementsToDetail.value[4].averages.push(parseFloat(incomingInvoiceEntity.preTaxAmount))
        } else if (incomingInvoiceEntity.type == 'accommodation') {
          elementsToDetail.value[5].preTaxAmount += parseFloat(incomingInvoiceEntity.preTaxAmount)
          elementsToDetail.value[5].averages.push(parseFloat(incomingInvoiceEntity.preTaxAmount))
        } else if (incomingInvoiceEntity.type == 'production') {
          elementsToDetail.value[6].preTaxAmount += parseFloat(incomingInvoiceEntity.preTaxAmount)
          elementsToDetail.value[6].averages.push(parseFloat(incomingInvoiceEntity.preTaxAmount))
        }
      })
    }

    const getAverage = (averages) => {
      if (averages.length == 0) {
        return 0
      } else {
        let average = 0
        averages.forEach(a => average += a)

        return average / averages.length
      }

    }

    const reloadIndicators = () => {
      fetchExpensesIndicator({
        start: moment(startLocal.value).format('YYYY-MM-DD'),
        end: moment(endLocal.value).format('YYYY-MM-DD'),
        employees: employeesLocal.value.map(e => e.id)
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // fetchExpensesIndicator({
    //   start: moment(props.start).format('YYYY-MM-DD'),
    //   end: moment(props.end).format('YYYY-MM-DD')
    // })
    reloadIndicators()

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      startLocal,
      endLocal,
      isInvoiced,
      totalOrdered,
      totalInvoiced,
      isModalDetailOpen,
      modalTitle,
      elementsToDetail,

      // Computed
      expensesIndicator,
      xAxis,
      yAxix,
      expensesByPercentDesc,
      // purchasesOrderByPercentDesc,

      // Methods
      // purchaseByMonths,
      chartClick,
      getAverage
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    start: function (val) {
      console.log(val)
      this.startLocal = moment(val)
    },
    end: function (val) {
      this.endLocal = moment(val)
    },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>